<template>

</template>

<script>
export default {
  name: "Common",
  data(){
    return{
      need_Login: 0,
    }
  }
}
</script>

<style scoped>

</style>
