/*
 * @Description:
 * @Author: charles
 * @Date: 2020-10-26 16:39:14
 * @LastEditors: [you name]
 * @LastEditTime: 2021-10-12 20:25:03
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // {
  //   path:"/",
  //   redirect:"/home"
  // },

  {
    path: '/',
    name: 'Home',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/homeView',
    name: 'HomeView',

    component: () => import('@/views/HomeView'),
    hidden: true
  },
  {
    path: '/register',
    name: 'Register',

    component: () => import('@/views/Register'),
    hidden: true
  },
  {
    path: '/peopleInfo',
    name: 'PeopleInfo',

    component: () => import('@/views/PeopleInfo'),
    hidden: true
  },
  {
    path: '/passwordInfo',
    name: 'passwordInfo',

    component: () => import('@/views/passwordInfo'),
    hidden: true
  },
  {
    path: '/shortMessageLog',
    name: 'ShortMessageLog',

    component: () => import('@/views/ShortMessageLog'),
    hidden: true
  },
  {
    path: '/caseBinding',
    name: 'CaseBinding',

    component: () => import('@/views/CaseBinding'),
    hidden: true
  },
  {
    path: '/lawyerExamination',
    name: 'LawyerExamination',

    component: () => import('@/views/LawyerExamination'),
    hidden: true
  },
]


const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes,

})
// 添加全局前置守卫  
router.beforeEach((to, from, next) => {

  // 检查路由名称  
  if (to.name === 'logins') {
    // 如果目标路由是 'logins'，则正常跳转  
    next();
  } else if (to.name === 'noLogins') {
    // 如果目标路由是 'noLogins'，则取消跳转  
    next(false);
  } else {
    // 默认情况，正常跳转  
    next();
  }

})

export default router
