<!--
 * @Author: [you name]
 * @Date: 2021-09-22 14:16:56
 * @LastEditors: [you name]
 * @LastEditTime: 2021-10-12 20:23:41
 * @Description: 封装的头部和底部
-->
<template>
  <!-- <div> -->
  <!-- 导航栏 -->
  <div class="nav">
    <!-- <div class="wrapper1"> -->
    <ul class="menu">
      <div class="menu-title">短信服务平台</div>
      <li
        @click="selectedItemId = cateItem.id"
        :class="{ active: selectedItemId === cateItem.id }"
        v-for="cateItem in category_1"
        :key="cateItem.id"
      >
        <router-link
          class="nav_link menu-icon"
          v-if="cateItem.id == 1"
          to="/"
          style="color: #7d7d7d"
          ><img class="menu-icon-right" :src="cateItem.icon" alt="" />{{
            cateItem.name
          }}</router-link
        >
        <a
          class="nav_link menu-icon"
          v-else-if="cateItem.id.indexOf('http') != -1"
          :href="cateItem.id"
          target="_blank"
          style="color: #7d7d7d"
          ><img class="menu-icon-right" :src="cateItem.icon" alt="" />{{
            cateItem.name
          }}</a
        >
        <span
          @click="list_params(cateItem)"
          class="nav_link menu-icon"
          v-else
          style="color: #7d7d7d"
          ><img class="menu-icon-right" :src="cateItem.icon" alt="" />{{
            cateItem.name
          }}</span
        >
      </li>
    </ul>
    <!-- </div> -->
  </div>
  <!-- </div> -->
</template>

<script>
//引入get
import { get } from "../../utils/request";
import $ from "jquery";

export default {
  data() {
    return {
      category_1: [
        {
          id: "homeView",
          name: "首页",
          icon: require("../../assets/home/home.png"),
        },
        {
          id: "duanxinrizhijilu",
          name: "短信日志记录",
          icon: require("../../assets/home/dxrz.png"),
        },
        {
          id: "anjianbangding",
          name: "案件绑定",
          icon: require("../../assets/home/ajbd.png"),
        },
        {
          id: "lvshiyuejuan",
          name: "律师阅卷",
          icon: require("../../assets/home/lsyj.png"),
        },
        {
          id: "gerenxinxi",
          name: "个人信息",
          icon: require("../../assets/home/grxx.png"),
        },
        {
          id: "xiugaimima",
          name: "修改密码",
          icon: require("../../assets/home/xgmm.png"),
        },
      ],
      selectedItemId: "",
      //点击搜索
      newsSearch: "",

      //用户名
      input_username: "",

      //密码
      input_password: "",
    };
  },
  methods: {
    list_params(cateItem) {
      if (cateItem.id == "duanxinrizhijilu") {
        this.$router.push({
          name: "ShortMessageLog",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
          // query:{
          //     id: cateItem.id,
          //     name: cateItem.name,
          // }
        });
      } else if (cateItem.id == "anjianbangding") {
        this.$router.push({
          name: "CaseBinding",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
        });
      } else if (cateItem.id == "lvshiyuejuan") {
        this.$router.push({
          name: "LawyerExamination",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
        });
      } else if (cateItem.id == "gerenxinxi") {
        this.$router.push({
          name: "PeopleInfo",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
        });
      } else if (cateItem.id == "xiugaimima") {
        this.$router.push({
          name: "passwordInfo",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
        });
      } else {
        this.$router.push({
          name: "HomeView",
          params: {
            id: cateItem.id,
            name: cateItem.name,
          },
        });
      }
    },
  },
  // vue生命周期函数
  created() {
    this.selectedItemId = this.category_1[0].id;
    //this.getCategoryData();
  },
};
</script>

<style lang="scss" scoped>
// 导航栏
.nav {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw * 240 / 1920);
    height: calc(100vh * 920 / 1080);
    background: rgba(255, 255, 255, 1);
    .menu-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100vh * 60 / 1080);
      font-size: calc(100vw * 20 / 1920);
      font-weight: bold;
      color: #fff;
      background-image: url("../../assets/home/title_bg.png");
      /* background-image: url("./assets/home/head_bg.png"); */
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-bottom: calc(100vh * 30 / 1080);
    }
    li {
      display: flex;
      justify-content: center;
      height: calc(100vh * 60 / 1080);
      width: calc(100vw * 216 / 1920);
      float: left;
      font-size: calc(100vh * 20 / 1080);
      cursor: pointer;
      line-height: calc(100vh * 60 / 1080);
      // margin: 10px 7px;
      color: #7d7d7d;
      border-top: 1px dashed #c2c1c1ec;
      // border-bottom: 1px dashed #7d7d7d;
      // font-weight: bold;
    }
    li:last-child {
      border-bottom: 1px dashed #c2c1c1ec;
      // border-bottom: 1px dashed #7d7d7d;
      // font-weight: bold;
    }
    li:hover {
      background-color: rgba(219, 214, 214, 0.493);
    }
  }
  // }
}

.nav_link {
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: calc(100vw * 10 / 1920);
  box-sizing: border-box;
}

.el-carousel__item {
  height: max-content;
}

.el_input_username {
  width: calc(100vw * 200 / 1920);
  margin: auto calc(100vw * 10 / 1920) auto 0;
}

.el_input_password {
  width: calc(100vw * 220 / 1920);
  margin: auto calc(100vw * 10 / 1920) auto 0;
}

// .el_input {
//   width: 800px;
//   display: flex;
//   vertical-align: middle;
//   align-items: center;
//   //justify-content:center;
//   //align-items:center;
// }
.menu-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu-icon-right {
  width: calc(100vw * 20 / 1920);
  // height: 20px;
  margin-right: calc(100vw * 6 / 1920);
}
.active {
  background-color: #c8ebfd;
}
</style>

