/*
 * @Author: [you name]
 * @Date: 2021-09-22 14:16:56
 * @LastEditors: [you name]
 * @LastEditTime: 2021-09-24 09:33:44
 * @Description:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/styles.scss'
import '@/style/main.scss'
import * as filters from './filters'
import $ from 'jquery'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import { parseTime } from "@/utils/ruoyi";
Vue.prototype.$video = Video
// 分页组件
import Pagination from "@/components/Pagination";

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import Common from './views/components/Common'
Vue.prototype.common_lg = Common
Vue.use(VideoPlayer);
Vue.use(ElementUI)
Vue.component('Pagination', Pagination)
Vue.prototype.parseTime = parseTime

// 页面跳转后滚动条位于最上方
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});


// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
import qs from 'qs';
import axios from 'axios';

// 请求的拦截器
axios.interceptors.request.use(function (config) {
  // 判断请求的类型
  // 如果是post请求就把默认参数拼到data里面
  // 如果是get请求就拼到params里面
  // if(config.method === 'post') {
  //   let data = qs.parse(config.data)

  //   config.data = qs.stringify({
  //     companyId: process.env.VUE_APP_BASE_CODE,
  //     ...data
  //   })
  // } else if(config.method === 'get') {
  //   config.params = {
  //     companyId: process.env.VUE_APP_BASE_CODE,
  //     ...config.params
  //   }
  // }
  return config;
}, function (error) {
  return Promise.reject(error);
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
