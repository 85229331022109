<template>
  <div id="app" style="height: auto; margin: 0 auto">
    <router-view v-if="$route.fullPath == '/'" :key="$route.fullPath" />
    <router-view v-if="$route.fullPath == '/register'" :key="$route.fullPath" />
    <div
      v-if="$route.fullPath != '/' && $route.fullPath != '/register'"
      style="height: calc(100vh*1112/1080); margin: 0 auto;background: linear-gradient(to bottom, rgba(119,167,189,1), #F5F5F5, #F5F5F5, #F5F5F5); "
    >
      <Header></Header>

      <div
        style="
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: calc(100vw*1112/1920);
          height: auto;
          margin: calc(100vh * 20 / 1080) auto 0;
        "
      >
        <Menu></Menu>
        <div
          style="
            width: calc(100vw*1112/1920);
            height: auto;
            margin-left: calc(100vw*10/1920);
            background-color: white;
            padding: calc(100vw*20/1920);
            box-sizing: border-box;
          "
        >
          <div style="width: calc(100vw*1012/1920); height: auto">
            <router-view :key="$route.fullPath" />
            <!--                    <LoginDialog></LoginDialog>-->
            <!-- <Footer></Footer> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./views/components/Header";
import Menu from "./views/components/Menu";
import Footer from "./views/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Menu,
    Footer,
  },
  data(){
    return{
      userData:{}
    }
  },
  methods: {},
  created() {

  },
};
</script>
<style>
.head-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh * 115 / 1080);
  background-image: url("./assets/home/topbg.jpg");
  /* background-image: url("./assets/home/head_bg.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: calc(100vw * 162 / 1080);
  padding-right: calc(100vw * 158 / 1080);
  box-sizing: border-box;
}
.head-img-logo {
  width: calc(100vw * 370 / 1080);
}
.head-img-exit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: calc(100vw * 12 / 1080);
}
.hie-img {
  width: calc(100vw * 12 / 1080);
  margin-right: calc(100vw * 5 / 1080);
}
.el-pagination.is-background {
  white-space: wrap !important;
  
}
::v-deep .el-table {
  font-size: calc(100vw * 14 / 1920) !important;
}

::v-deep .el-table .el-table__header-wrapper th,
::v-deep .el-table .el-table__fixed-header-wrapper th {
  height: calc(100vh * 40 / 1080) !important;
  font-size: calc(100vw * 13 / 1920) !important;
}

::v-deep .el-button--mini,
.el-button--small {
  font-size: calc(100vw * 12 / 1920) !important;
}

::v-deep .el-table--medium .el-table__cell {
  padding: calc(100vw * 10 / 1920) 0 !important;
}

::v-deep .el-form-item__label {
  font-size: calc(100vw * 14 / 1920) !important;
  line-height: calc(100vh * 40 / 1080) !important;
  padding: 0 calc(100vw * 12 / 1920) 0 0 !important;
}

::v-deep .el-input--small .el-input__inner {
  font-size: calc(100vw * 14 / 1920) !important;
  height: calc(100vh * 32 / 1080) !important;
  line-height: calc(100vh * 32 / 1080) !important;
}

::v-deep .el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: calc(100vh * 36.3 / 1080) !important;
}

::v-deep .el-input--small .el-input__icon {
  font-size: calc(100vw * 14 / 1920) !important;
  line-height: calc(100vh * 32 / 1080) !important;
}

/* ::v-deep .el-input--medium .el-input__inner {
  font-size: calc(100vw*14/1920) !important;
    height: calc(100vh*36/1080) !important;
    line-height: calc(100vh*36/1080) !important;
} */
::v-deep .el-input__inner {
  font-size: calc(100vw * 14 / 1920) !important;
  border-radius: calc(100vw * 4 / 1920) !important;
  border: calc(100vw * 1 / 1920) solid #dcdfe6;
  /* height: calc(100vh * 40 / 1080) !important;
  line-height: calc(100vh * 40 / 1080) !important; */
  outline: 0;
  padding: 0 calc(100vw * 15 / 1920) !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: calc(100vw * 20 / 1920) !important;
  padding-left: calc(100vw * 30 / 1920) !important;
}

::v-deep .el-checkbox__label {
  padding-left: calc(100vw * 10 / 1920) !important;
  line-height: calc(100vh * 19 / 1080) !important;
  font-size: calc(100vw * 14 / 1920) !important;
}

::v-deep .el-checkbox__inner {
  width: calc(100vw * 14 / 1920) !important;
  height: calc(100vw * 14 / 1920) !important;
}

::v-deep .el-table .cell.el-tooltip {
  min-width: calc(100vw * 50 / 1920) !important;
}

::v-deep .el-button--medium {
  padding: calc(80vh * 10 / 1080) calc(100vw * 9 / 1920) !important;
  font-size: calc(100vw * 14 / 1920) !important;
}

::v-deep .el-textarea__inner {
  font-size: calc(100vw * 13 / 1920) !important;
  padding: calc(100vh * 5 / 1080) calc(100vw * 15 / 1920) !important;
  border: calc(100vw * 1 / 1920) solid #dcdfe6 !important;
  border-radius: calc(100vw * 4 / 1920) !important;
}

::v-deep .el-form-item__error {
  font-size: calc(100vw * 14 / 1920) !important;
  padding-top: calc(100vh * 4 / 1080) !important;
}

::v-deep .el-input--medium .el-input__icon {
  line-height: calc(100vh * 36 / 1080) !important;
}

::v-deep .el-tree-node__content {
  height: calc(100vh * 26 / 1080) !important;
  font-size: calc(100vw * 15 / 1920) !important;
}

::v-deep .el-tree__empty-text {
  font-size: calc(100vw * 15 / 1920) !important;
}

::v-deep .el-radio {
  margin-right: calc(100vw * 30 / 1920) !important;
}

::v-deep .el-radio,
.el-radio--medium.is-bordered .el-radio__label {
  font-size: calc(100vw * 16 / 1920) !important;
}

::v-deep .el-radio__inner {
  border: calc(100vw * 1 / 1920) solid #dcdfe6 !important;
  border-radius: 100%;
  width: calc(100vw * 14 / 1920) !important;
  height: calc(100vw * 14 / 1920) !important;
}

::v-deep .el-radio__inner::after {
  width: calc(100vw * 4 / 1920) !important;
  height: calc(100vw * 4 / 1920) !important;
}

::v-deep .el-radio__label {
  font-size: calc(100vw * 16 / 1920) !important;
  padding-left: calc(100vw * 10 / 1920) !important;
}

::v-deep textarea#warnning {
  height: calc(100vh * 140 / 1080);
}

::v-deep textarea.el-textarea__inner {
  height: calc(100vh * 140 / 1080);
}

/* ::v-deep .pagination-container {
  height: calc(100vh * 25 / 1080) !important;
  margin-bottom: calc(100vh * 10 / 1080) !important;
  margin-top: calc(100vh * 15 / 1080) !important;
  padding: calc(100vw * 10 / 1920) calc(100vw * 20 / 1920) !important;
}
::v-deep .el-pagination {
  padding: calc(100vw * 2 / 1920) calc(100vw * 5 / 1920) !important;
}
::v-deep .el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  font-size: calc(100vw * 13 / 1920) !important;
  min-width: calc(100vw * 35.5 / 1920) !important;
  height: calc(100vh * 28 / 1080) !important;
  line-height: calc(100vh * 28 / 1080) !important;
} */
/* ::v-deep .el-pagination__total {
    margin-right: calc(100vw * 10 / 1920) !important;
}
::v-deep .el-pager li {
    padding: 0 calc(100vw * 4 / 1920) !important;
    font-size: calc(100vw * 13 / 1920) !important;
    min-width: calc(100vw * 35.5 / 1920) !important;
    height: calc(100vh * 28 / 1080) !important;
    line-height: calc(100vh * 28 / 1080) !important;
}
::v-deep .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    margin: 0 calc(100vw * 2 / 1920) !important;
    min-width: calc(100vw * 30 / 1920) !important;
    border-radius:calc(100vw * 2 / 1920) !important;
}
::v-deep .el-pagination__jump {
    margin-left:  calc(100vw * 12 / 1920) !important;
}
::v-deep .el-pagination .el-select .el-input {
    width: calc(100vw * 100 / 1920) !important;
    margin: 0 calc(100vw * 5 / 1920) !important;
} */
.monitors ::v-deep .el-input__inner {
  height: calc(100vh * 40 / 1080) !important;
  line-height: calc(100vh * 40 / 1080) !important;
}

::v-deep .tox-tinymce {
  border: calc(100vw * 1 / 1920) solid #dcdfe6 !important;
  border-radius: calc(100vw * 4 / 1920) !important;
}

.el-select-dropdown__item {
  font-size: 12px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

/* ::v-deep .el-checkbox__inner{
    background-color: #277eff;

   
} */
::v-deep .left-network-checkbox .el-checkbox__label {
  color: #277eff;
}

::v-deep .left-network-txt .el-checkbox__label {
  line-height: calc(100vh * 0 / 1080) !important;
}

::v-deep .left-network-txt .el-checkbox__input {
  line-height: calc(100vh * 0 / 1080) !important;
}
</style>